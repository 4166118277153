<template>
  <form @submit.prevent="submit" id="form-field" class="login-form">
    <page :data="pageData" :is-child="_isChild">
       <template #cmslefttype(vaddress-listitem)="{data: d}">
         <list-item :data="d.Data">
           <template #text>
             <span :class="d.Data.TextClass">{{ d.Data.Text }} <router-link replace  :to="d.Data.AltTo">{{d.Data.AltText}}</router-link></span>
           </template>
         </list-item>
       </template>
      <template #cmslefttype(form)="{data: d}">
        <all-field :data="d.Data" @error="setErrors"></all-field>
          <div class="flex justify-center" v-if="type !== 'login'">
            <b-field>
              <b-checkbox v-model="agree" type="is-white">{{config.TermsText}} <strong><x-link class="text-yellow-500" :to="config.TermsURL">{{config.TermsLinkText}}</x-link></strong></b-checkbox>
          </b-field>
          </div>
          <div class="text-right mb-4">
            <router-link v-if="type === 'login'" class="text-white mr-2" to="/forgetpassword">Lupa kata sandi?</router-link>
          </div>
          <div class="flex flex-col w-full justify-end">
            <b-button class="w-full" type="is-login" native-type="submit" :disabled="!agree || hasError">{{d.SubmitButton || "Save"}}</b-button>
            <span class="my-4 text-center">-atau-</span>
            <span class="text-center">Masuk dengan</span>
            <div class="flex justify-center w-full my-4">
              <google-login-button v-if="hasGoogleLogin" :class="{'mr-4': hasFacebookLogin}" @click="socialLogin('google')" :disabled="!agree"></google-login-button>
              <facebook-login-button v-if="hasFacebookLogin" @click="socialLogin('facebook')" :disabled="!agree"></facebook-login-button>
            </div>
          </div>
          <div class="text-center">
            {{config.AltText}} <router-link class="text-white font-bold" :to="config.AltLinkTo">{{config.AltLinkText}}</router-link>
          </div>

          <Modal :visible="isModalVisible" @close="isModalVisible = false">
            <template v-slot:body>
              <ul>
                <li v-for="(item, index) in policyList" :key="index" @click="handleItemClick(item)">
                  <div class="flex justify-between w-full mt-4" style="color: #555">
                    <div class="text-lg font-bold">
                      {{ item.Title }}
                    </div>
                    <div>
                      <b-icon icon="chevron-right"></b-icon>
                    </div>
                  </div>
                </li>
              </ul>
            </template>
          </Modal>
          <div v-if="$route.path == '/login'" @click="policyAction" class="text-center" style="margin-top: 20vh">
            <div style="color: #EFA2A2">
              Untuk pelajari lebih lanjut, <span class="text-white font-bold">klik sini</span>
            </div>
          </div>
          <div v-if="$route.path == '/login'" @click="guestAction" class="text-center" style="margin-top: -20vh">
            <div style="color: #EFA2A2">
              Akses tamu, <span class="text-white font-bold">klik sini</span>
            </div>
          </div>
      </template>
    </page>
  </form>
</template>

<script>
import Vue from 'vue'
import BaseApi from '@/libs/baseApi'
import { device as Device } from '@/libs/portalApi'
import FormPage from './base/FormPage'

export default {
  extends: FormPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem'),
    XLink: () => import('@/components/link/Link'),
    GoogleLoginButton: () => import('@/components/social/GoogleLoginButton'),
    FacebookLoginButton: () => import('@/components/social/FacebookLoginButton'),
    Modal: () => import('@/components/modal/index')
  },
  data () {
    return {
      agree: true,
      isSocialLogin: false,
      policyList: [],
      isModalVisible: false
    }
  },
  methods: {
    guestAction () {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push('/user/dashboard')
      }
    },
    async policyAction () {
      this.policyList = await this.$baseApi.get('/help')
      this.isModalVisible = true
    },
    handleItemClick (item) {
      if (typeof cordova !== 'undefined') {
        window.cordova.InAppBrowser.open(item.URL, '_blank', 'location=no')
      } else {
        window.open(item.URL, '_blank')
      }
      this.isModalVisible = false
    },
    async submit () {
      this.$store.commit('service/SET_CONDITION_NOTIF', true)
      const loading = this.$buefy.loading.open()
      try {
        const data = this.getData('form-field')
        if (this.type === 'login') {
          await this.login(data)
          // await this.checkToken()
        } else {
          await this.register(data)
        }
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async socialLogin (provider) {
        const loading = this.$buefy.loading.open()
        let response
        try {
          if (this.isCordova) {
            response = await this.socialLoginCordova(provider)
          } else {
            response = await this.$auth.authenticate(provider)
          }
          const res = await this.$portalApi.get(`/auth/login/external/${provider}/callback`, { params: { ...response, system: this.company.Company } })
          this.isSocialLogin = true
          if (this.isCordova) this.socialLogout(provider)
          await this.handleLoginResponse(res)
        } catch (err) {
          if (err.message) alert(err.message)
        } finally {
          loading.close()
        }
    },
    socialLoginCordova (provider) {
      return new Promise((resolve, reject) => {
        if (provider === 'facebook') {
          window.facebookConnectPlugin.login(['email'], (res) => {
            resolve({ token: res.authResponse.accessToken })
          }, (err) => {
            reject(err)
          })
        } else if (provider === 'google') {
          window.plugins.googleplus.login({
            webClientId: '3054971848-ohd4m4eq380ikfkop4062thiskbbfqge.apps.googleusercontent.com',
            offline: true
          }, (user) => {
            resolve({ token: user.accessToken })
          }, (err) => {
            reject(new Error(err === 12501 ? '' : err))
          })
        }
      })
    },
    socialLogout (provider) {
      if (provider === 'facebook') {
        window.facebookConnectPlugin.logout(() => {
          console.log('Log out from facebook')
        }, (err) => {
          console.log('Error log out from facebook: ', err)
        })
      }

      if (provider === 'google') {
        window.plugins.googleplus.disconnect(() => {
          console.log('Log out from google')
        })
      }
    },
    async checkToken () {
        var config = JSON.parse(localStorage.getItem('config'))
        var refToken = JSON.parse(localStorage.getItem('refresh_token'))
        var company = JSON.parse(localStorage.getItem('company'))
        var token = { company: company.Company, refresh_token: refToken }
        const res = await this.$adminApi.post('/checktokenexpiry', token)
        if (res === 1) console.log('')
        else if (res.data === '0') this.logout()
        else {
            config.Token = res.access_token
            localStorage.setItem('config', JSON.stringify(config))
            localStorage.setItem('refresh_token', res.refresh_token)
        }
    },
    async login (data) {
      const response = await this.$portalApi.post('/auth/login', {
          ...data,
          Company: this.company.Company,
          Device
      })
      return this.handleLoginResponse(response)
    },
    async register ({ FirstName, LastName, ConfirmPassword, ...data }) {
      await this.$portalApi.post('/auth/register', {
        ...data,
        Name: FirstName + ' ' + LastName,
        Company: this.company.Company
      })
      await this.login({ UserName: data.UserName, Password: data.Password })
    },
    async handleLoginResponse (response) {
      const config = JSON.parse(localStorage.getItem('config') || '{}')
      config.Token = typeof response === 'string' ? response : response.access_token
      localStorage.setItem('config', JSON.stringify(config))
      localStorage.setItem('refresh_token', JSON.stringify(response.refresh_token))

      const newBaseApi = new BaseApi(localStorage.getItem('config'))
      Vue.prototype.$baseApi = newBaseApi.api()

      this.$socket.connect()
      await this.loadContents()

      this.onesignallogin()
      this.onesignal()
      this.$store.dispatch('socket/getNotification')
        this.$router.replace('/user')
    },
    async loadContents () {
      const { UserName, Password, ConfirmPassword, ...data } = this.getData('form-field')
      if (this.type === 'register' && !this.isSocialLogin) {
        await this.$baseApi.post('/user/edit', data)
      }
      return Promise.all([
        this.getCompany(),
        // this.$store.dispatch('webcontent/get', { path: '/home' }),
        this.$store.dispatch('webcontent/get', { path: '/user/dashboard' })
        // this.$store.dispatch('webcontent/get', { path: '/user/orders/packages' })
      ])
    }
  },
  computed: {
    logo () {
        return this.$store.state.service.company.Logo
    },
    type () {
      return this.$route.name
    },
    data () {
      return (this.cmsLeft.Data || [])[0] || {}
    },
    altLink () {
      return this.type === 'login' ? 'register' : 'login'
    },
    hasFacebookLogin () {
      return this.config.FacebookLogin && !this.isIos
    },
    hasGoogleLogin () {
      return this.config.GoogleLogin && !this.isIos
    }
  }
}
</script>
